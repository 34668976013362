<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="flex flex-col min-h-screen relative">
    <AlertBanner class="print:hidden" />
    <AlertContainer class="print:hidden" />
    <AppHeader
      class="print:hidden"
      :class="{ '!relative': disableStickyHeader }"
    />

    <div class="flex-grow">
      <breadcrumbs colour="blue" :items="crumbs" />
      <div class="container py-4 mb:py-12 xl:py-8">
        <div class="flex flex-row space-x-10">
          <div class="flex-1">
            <div class="flex justify-between">
              <h1 class="mb-4 text-3xl font-bold">
                {{ getCleanName(route.name?.toString()) }}
              </h1>
              <div class="flex space-x-2 hidden md:block">
                <tafe-button @click="hideNavigation = !hideNavigation">{{
                  hideNavigation ? 'Show Nav' : 'Hide Nav'
                }}</tafe-button>
                <tafe-button
                  @click="disableStickyHeader = !disableStickyHeader"
                  >{{
                    disableStickyHeader ? 'Relative Header' : 'Sticky Header'
                  }}</tafe-button
                >
              </div>
            </div>
            <slot></slot>
          </div>
          <div
            v-if="!hideNavigation"
            class="w-60 max-h-[60vh] overflow-y-auto sticky top-10 overscroll-none hidden md:block"
          >
            <div v-show="showNavGrid">
              <div>
                <div class="grid grid-cols-4 gap-3">
                  <div
                    v-for="(item, index) in navGrid"
                    :key="`nav_grid_${index}`"
                    class="px-3 py-2 border border-brand-blue-600 rounded text-brand-blue-600 text-center"
                    :class="
                      item.available
                        ? 'cursor-pointer hover:bg-brand-blue-600 hover:border-brand-blue-600 hover:text-white'
                        : 'border-brand-grey-200 text-brand-grey-200 cursor-not-allowed'
                    "
                    @click.prevent="scrollIntoView(`group_${item.letter}`)"
                  >
                    {{ item.letter }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="!showNavGrid"
              class="flex flex-col space-y-1 border-l border-brand-blue-200"
            >
              <div
                v-for="(group, letter) in groupedRoutes()"
                :id="`group_${letter}`"
                :key="`group_${letter}`"
              >
                <div
                  class="flex group px-2 py-1 bg-brand-blue-600 text-white sticky top-0 cursor-pointer"
                  @click.prevent="showNavGrid = !showNavGrid"
                >
                  <div class="flex-1">{{ letter }}</div>
                  <div class="ml-2 hidden group-hover:inline-block">
                    <icon name="IconChevronUp" class="align-middle" />
                  </div>
                </div>
                <div class="flex flex-col">
                  <nuxt-link
                    v-for="(nav, i) in group"
                    :key="`test_nav_${i}`"
                    :to="nav"
                    class="py-1 px-2 hover:bg-brand-blue-200"
                    :class="{
                      'bg-brand-blue-200': nav.path === route.path
                    }"
                  >
                    {{ getCleanName(nav.name?.toString()) }}
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AppFooter class="print:hidden" />
  </div>
</template>
<script setup lang="ts">
import useQueryString from '~/composables/useQueryString'
import { useSiteConfigStore } from '~/stores/siteConfig'

const route = useRoute()
const crumbs = computed(() => [
  {
    text: 'Testing',
    to: '/testing'
  },
  {
    text: getCleanName(route.name?.toString()),
    to: route.path
  }
])

const testingRoutes = useRouter()
  .getRoutes()
  .filter((r) => /^\/testing\//.test(r.path))

const { computedQueryFlag } = useQueryString()

const groupedRoutes = () => {
  const routes = testingRoutes.reduce((o, k) => {
    const letter =
      k.name?.toString().split('-')[1].charAt(0).toUpperCase() || '_other'
    return { ...o, [letter]: [...(o[letter as keyof typeof o] || []), k] }
  }, {})

  return Object.keys(routes)
    .sort()
    .reduce((o, k) => ({ ...o, [k]: routes[k as keyof typeof routes] }), {})
}

const siteConfigStore = useSiteConfigStore()
await siteConfigStore.fetch()

const showNavGrid = ref(false)

const hideNavigation = computedQueryFlag('hide-nav')
const disableStickyHeader = computedQueryFlag('disable-sticky-header')

const navGrid = computed(() => {
  const availableLetters = Object.keys(groupedRoutes())
  const alphabet = Array.from(Array(26)).map((e, i) =>
    String.fromCharCode(i + 65)
  )

  return alphabet.map((letter) => ({
    letter,
    available: availableLetters.includes(letter)
  }))
})

const scrollIntoView = (target: string) => {
  showNavGrid.value = false

  setTimeout(
    (tgt) => {
      const el = document.getElementById(tgt)
      const container = el?.parentElement?.parentElement

      const containerHeight = container?.clientHeight || 0
      const containerCenter = Math.floor(containerHeight / 2)

      const elOffset = el?.offsetTop || 0
      const elHeight = el?.clientHeight || 0
      const elCenter = Math.floor(elHeight / 2)
      const scrollTo = Math.max(0, elOffset - containerCenter + elCenter)

      container?.scrollTo(0, scrollTo)
    },
    25,
    target
  )
}

const getCleanName = (name: string | undefined) => {
  if (!name) return 'undefined'

  return name
    .split('-')
    .slice(1)
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')
}
provide('IsInternational', false)
</script>
